<template>
  <div>
    <div>
      <div class="row">
        <div class="col-12">
          <div class="card card-custom gutter-b example example-compact">
            <div
              class="
                card-header
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <div class="card-title">
                <h3 class="card-label">To'lov vedimost batafsil</h3>
              </div>
              <div class="card-button">
                <costCategoriesCreate />
              </div>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div v-if="getDetails.total_elements !== undefined">
                  <div class="Input">
                    <label>Jami</label>
                    <input
                      type="text"
                      disabled
                      class="input-text"
                      :value="
                        getDetails.total_elements.total.toLocaleString('zh-ZH')
                      "
                    />
                  </div>
                </div>
                <div class="Input">
                  <label>Sana</label>
                  <input
                    type="text"
                    disabled
                    class="input-text"
                    :value="getDetails.oper_date"
                  />
                </div>
                <div class="Input" v-if="getDetails.payed_status == false">
                  <label>T'olov status</label>
                  <input
                    type="text"
                    disabled
                    class="input-text"
                    value="Neoplachen"
                  />
                </div>
                <div v-else class="Input">
                  <label>T'olov status</label>
                  <input
                    type="text"
                    disabled
                    class="input-text"
                    value="Oplachen"
                  />
                </div>
                <div v-if="getDetails.payed_status == true" class="Input">
                  <label>Tranzaksiya</label>
                  <input
                    type="text"
                    disabled
                    class="input-text"
                    :value="getDetails.transaction"
                  />
                </div>
                <div class="Input" v-if="getDetails.payment_source == 1">
                  <label>T'olov turi</label>
                  <input type="text" disabled class="input-text" value="Bank" />
                </div>
                <div class="Input" v-if="getDetails.payment_source == 2">
                  <label>T'olov turi</label>
                  <input
                    type="text"
                    disabled
                    class="input-text"
                    value="Kassa"
                  />
                </div>
              </div>
              <div>
                <v-autocomplete
                  v-if="!getDetails.payed_status"
                  v-model="transfer"
                  :items="transfersList"
                  item-value="id"
                  item-text="name"
                  outlined
                  dense
                  return-object
                  label="Vipiska Tranzaksiyalar"
                ></v-autocomplete>
              </div>

              <v-data-table
                v-if="!loading"
                :headers="headers"
                :loading="isLoading"
                loading-text="...."
                :items="getDetails.paymentstatementelements_set"
                :page.sync="page"
                :items-per-page="10"
                hide-default-footer
              >
                <template v-slot:[`item.index`]="{ item }">
                  {{
                    getDetails.paymentstatementelements_set
                      .map(function (x) {
                        return x.id
                      })
                      .indexOf(item.id) + 1
                  }}
                </template>
                <template v-slot:[`item.amount`]="{ item }">
                  {{ item.amount.toLocaleString('zh-ZH') }}
                </template>
              </v-data-table>
              <div
                class="mt-5 d-flex justify-content-between align-items-center"
              >
                <div class="d-flex">
                  <v-btn
                    color="primary"
                    class="mr-2"
                    text-white
                    v-if="transfer !== ''"
                  >
                    Umumiy Summa - {{ getTotal | mask }}
                  </v-btn>
                  <v-btn
                    color="warning text-dark"
                    v-if="transfer !== ''"
                    class="mr-2"
                    :class="
                      getTotal - transfer.amount > 1
                        ? 'text-warning'
                        : 'text-danger'
                    "
                  >
                    Farqi -
                    <!-- <span
                      :class="
                        getTotal - transfer.amount > 1
                          ? 'label-warning'
                          : 'label-danger'
                      "
                    ></span> -->
                    {{ (getTotal - transfer.amount) | mask }}
                  </v-btn>
                  <v-btn color="success text-white" v-if="transfer !== ''">
                    Tranzaksiya Summasi -

                    {{ transfer.amount | mask }}
                  </v-btn>
                </div>
                <div class="mr-2">
                  <v-btn
                    color="success"
                    @click="sendDdata"
                    v-if="transfer !== ''"
                    >Saqlash</v-btn
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  data() {
    return {
      transfer: '',
      headers: [
        { text: '#', value: 'index' },
        { text: 'Hodim', value: 'staffemployed_name' },
        { text: 'Tabel raqam', value: 'tabel_number' },
        { text: 'Summa', value: 'amount' }
      ]
    }
  },
  created() {
    this.$store.dispatch(
      'getDetailsSalaryPaymentStatements',
      this.$route.params.id
    )
  },
  filters: {
    mask(val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) return val.toLocaleString('es-US')
      else return 0
    }
  },
  computed: {
    transfersList() {
      const list = this.$store.state.requests.transferSalaryList
      list.forEach((x) => {
        x.name =
          x.type_statetransfer_name +
          ' summa: ' +
          x.amount.toLocaleString('zh-ZH') +
          ' | ' +
          x.comment
      })
      return list
    },
    getTotal() {
      const list = this.$store.state.requests.detailsSalaryPaymentStatements
      if (list.total_elements !== undefined) return list.total_elements.total
      else return list
    },
    getDetails() {
      const data = this.$store.state.requests.detailsSalaryPaymentStatements
      if (data.payment_source !== undefined) {
        this.$store.dispatch('getTransferSalaryList', {
          type_transfer: 'salary',
          payment_source: data.payment_source
        })
      }
      return data
    }
  },
  methods: {
    sendDdata() {
      if (this.transfer.amount !== undefined) {
        if (this.getTotal == this.transfer.amount) {
          const data = {
            transaction: this.transfer.id
          }
          this.$store.dispatch('updatePaymentStatemnts', {
            id: this.$route.params.id,
            data: data
          })
        } else {
          Swal.fire({
            title: '',
            text: 'Summalar qiymati teng emas!',
            icon: 'error'
          })
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Moliya' },
      { title: 'Ish haqi hisobotlari' },
      { title: "To'lov vedimost batafsil" }
    ])
  }
}
</script>

<style scoped>
.Input label {
  display: block;
  font-size: 12px;
}
.Input {
  margin-right: 6px;
  margin-bottom: 30px;
}
.Input:last-child {
  margin-right: 0;
}
.input-text {
  background-color: rgb(142 190 253 / 14%);
  padding: 0.2rem 0.5rem;
  border: 1px solid rgb(142 190 253 / 28%);
  color: inherit;
  /* color: rgb(24, 24, 24); */
  /* box-shadow: 0px 5px 7px 3px rgba(222, 222, 222, 0.66); */

  font-size: 16px;
  border-radius: 3px;
}
.col-4,
.col-12 {
  padding-left: 0;
}
.input__wrapper {
  display: flex;
  flex-wrap: wrap;
}
</style>
